body {
  margin: 0; 
  padding: 0;
  overflow-x: hidden; 
  max-width: 100vw; 
}

:root {
  --mandala-shift: 300px; 
  --tooltip-width: 600px; 
  --tooltip-left: -400px; 
}
.sistema-iky-container {
  padding: 20px;
  margin: 20px auto;
}

.mandala-container{
}

/* Titre principal */
.sistema-iky-titlep {
  font-size: 24px;
  margin-bottom: 16px;
  text-align: justify;
  color: white;
}

/* Texte principal */
.sistema-iky-textp {
  font-size: 18px;
  text-align: justify;
  line-height: 1.6;
  color: rgb(255, 255, 255);
}

.sistema-iky-p strong {
  color: #414445; /* Change the color for emphasis */
}


.sistema-iky-principiosp, .sistema-iky-modelo-teorico {
  margin-top: 20px;
  color: white;
  text-align: justify;
}

.sistema-iky-text-middle{
  font-size: 18px;
  display: flex;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  justify-content: center;
}

.sistema-iky-listp {
  list-style-type: disc;
  margin-left: 20px;
}

.sistema-iky-text {
  margin-bottom: 15px;
}


.mandala-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  transition: transform 0.5s ease-in-out; /* Transition fluide */
}


.mandala-iky-img {
  max-width: 50%; /* Limite la taille pour petits écrans */
  height: auto;
}

/* Zones interactives */
.interactive-area {
  position: absolute;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.mandala-tooltip {
  position: absolute;
  top: 50%;
  left: var(--tooltip-left);
  transform: translateY(-50%);
  background: #475840;
  border: 1px solid #ffffff;
  padding: 20px;
  z-index: 10;
  width: var(--tooltip-width);
  max-width: 100%; /* Empêche de dépasser la largeur de l'écran */
  white-space: normal;
  overflow: hidden;
  text-align: justify;
  border-radius: 8px;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.tooltip-close {
  position: absolute;
  top: 1px;
  left: calc(var(--tooltip-width) - 30px); /* Dynamique selon la largeur */
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sistema-iky-titlep {
    font-size: 18px;
  }

  .mandala-container {
    margin: 10px 0;
    transform: translateX(0); 
  }

  .interactive-area {
    /* Les zones interactives seront plus petites sur mobile */
    width: 12%;
    height: 10%;
  }

  .mandala-iky-img {
    max-width: 100%; /* L'image prend toute la largeur */
  }

  .mandala-tooltip {
    width: 90%;
    left: 5%; 
    top:25%
    
  }

  .mandala-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .mandala-tooltip-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente opcional */
    z-index: 1000; /* Asegura que esté sobre el mandala */
  }
  
  .mandala-tooltip {
    position: relative;
    /* background: #fff; */
    left: 0;
    border-radius: 8px;
    padding: 16px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .tooltip-close {
    position: absolute;
    top: 8px;
    right: 8px;
    left: auto;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff; /* Color del texto del botón */
    cursor: pointer;
    z-index: 10; /* Asegura que esté sobre otros elementos */
  }
  
  .tooltip-close:hover {
    color: red; /* Efecto al pasar el cursor */
  }
}


