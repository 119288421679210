.card-container-espacios {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 40px;
  padding-bottom: 50px;
}

.card-espacios {
  width: 600px;
  height: 500px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 30px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  flex: 0 1 600px; /* S'assurer que les cartes prennent 600px de large initialement, mais peuvent se rétrécir */
}

.front-content-espacios {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.front-content-espacios img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.front-content-espacios p {
  position: absolute;
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-weight: 700;
  background: linear-gradient(-45deg, #000000 0%, #000000 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0;
  text-align: center;
}

.content-espacios {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, #454040 50%, #3d302d 100%);
  color: #e8e8e8;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(-96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.heading-espacios {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
}

.card-espacios.large-card {
  transform: scale(1.2);
}

.card-espacios.small-card {
  transform: scale(0.9);
}

.card-espacios:hover .content-espacios {
  transform: translateY(0);
}

.card-espacios:hover .front-content-espacios {
  transform: translateY(30%);
}

.card-espacios:hover .front-content-espacios p {
  opacity: 0;
}

