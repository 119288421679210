/* Container du formulaire */
.container {
  display: flex;
  justify-content: center; /* Centrer le formulaire horizontalement */
  align-items: center; /* Centrer le formulaire verticalement */
  padding: 20px; /* Ajout de padding pour les petits écrans */
  height: 100vh; /* S'assurer que le container occupe tout l'écran */
  box-sizing: border-box;
}

/* Container pour Nom et Prénom */
.name-container {
  display: flex;
  justify-content: space-between;
  gap: 12px; /* Espace entre les deux champs */
}

.name-container input {
  flex: 1;
  min-width: 0; /* Évite les problèmes de débordement */
}

/* Autres ajustements */
.input-group {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Espacement entre les groupes */
}

/* Styles du formulaire */
.form {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.form h1 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* Centrage du texte */
}

.form p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center; /* Centrage du texte */
}

.form ::placeholder {
  font-size: 14px;
}

/* Champs de saisie */
.input-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f9f9f9;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 100%; /* Largeur dynamique */
}

.input-group input:focus {
  border-color: #B42424;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2); /* Ombre légère pour le focus */
}

/* Bouton */
.button-signupForm {
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: #B42424;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.button-signupForm:hover {
  background-color: #B42424;
  transform: translateY(-2px); /* Léger effet de hover */
}

.button-signupForm:active {
  transform: translateY(0); /* Retour à la position initiale */
}

/* Modal */
.modal-signup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-signup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.modal-content-signup h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.modal-content-signup p {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-content-signup button {
  padding: 10px 20px;
  background: #b42424;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-content-signup button:hover {
  background: #a11f1f;
}

/* Ajustements pour les petits écrans */
@media (max-width: 768px) {
  /* Formulaire */
  .form {
    max-width: 100%; /* Prend toute la largeur disponible */
    padding: 15px; /* Réduction des marges */
    border-radius: 5px;
  }

  .form h1 {
    font-size: 20px; /* Réduction de la taille des titres */
  }

  .form p {
    font-size: 12px; /* Réduction de la taille des paragraphes */
  }

  .input-group {
    gap: 10px; /* Réduction de l'espacement */
  }

  .input-group input {
    padding: 10px; /* Réduction de la hauteur */
    font-size: 14px; /* Réduction de la taille du texte */
  }

  .button-signupForm {
    padding: 10px; /* Réduction du padding */
    font-size: 14px; /* Réduction de la taille de la police */
  }

  /* Nom et Prénom */
  .name-container {
    flex-direction: column; /* Empile les champs en colonne */
    gap: 8px; /* Réduction de l'espacement */
  }

  /* Modal */
  .modal-content-signup {
    padding: 15px; /* Réduction du padding */
    border-radius: 5px; /* Coins légèrement arrondis */
  }

  .modal-content-signup h2 {
    font-size: 18px; /* Taille réduite */
  }

  .modal-content-signup p {
    font-size: 14px; /* Taille réduite */
  }

  .modal-content-signup button {
    padding: 8px 15px; /* Bouton plus compact */
    font-size: 14px; /* Taille réduite */
  }
}

.close-btn-signup {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  color: #B42424;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.close-btn-signup:hover {
  color: #8C1C1C;
}

@media (min-width: 768px) {
  .close-btn-signup {
    display: none;
  }
}