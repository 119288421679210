.text-caminoMaya {
  position: relative;
  text-align: justify;
  padding: 50px;
  line-height: 1.6;
  font-size: 18px;
  color: white; /* Le texte reste blanc */
  background-image: url('../../assets/photoPageRipey/caminoMaya/fuego_2.png');
  background-size: 550px 550px; /* Taille spécifique de l'image */
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1; /* Assure que le texte est au-dessus de la superposition */
}

.text-caminoMaya::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place l'image derrière le contenu */
  background-image: url('../../assets/photoPageRipey/caminoMaya/fuego_2.png');
  background-size: 550px 550px;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(1) sepia(1) saturate(10000%) hue-rotate(180deg); /* Applique la couleur à l'image */
  opacity: 0.7; /* Rendre l'image un peu transparente si nécessaire */
}

@media (max-width: 1000px) {
  .text-caminoMaya {
    padding: 5px; /* Moins de padding pour les petits écrans */
    font-size: 18px; /* Réduit la taille de la police */
    background-size: 350px 350px; /* Réduit la taille de l'image de fond pour qu'elle s'adapte mieux */
  }

  .text-caminoMaya::before {
    background-size: 350px 350px; /* Même réduction de la taille de l'image dans le ::before */
  }
}