.professor-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 40px;
  }
  
  .professor-card {
    position: relative;
    width: 280px;
    background: #21140e;
    transition: 0.3s ease;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 30px #8e8e8e);
    box-sizing: border-box;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    margin: 40px;
    overflow: hidden;
  }
  
  .professor-card:hover {
    filter: drop-shadow(0px 0px 30px #DC9424);
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 10px;
  }
  
  .profile-picture img {
    width: 100%;
    height: 110%;
    object-fit: cover;
  }
  
  .professor-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .professor-info h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .styled-button {
    width: 165px;
    height: 39px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    border-radius: 1rem;
    border: none;
    position: relative;
    background: #100720;
    transition: 0.3s ease;
  }
  
  .styled-button::after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle farthest-corner at 10% 20%, #DC9424 17.8%, #DC9424 100.2%);
    filter: blur(15px);
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .styled-button:hover {
    transform: scale(0.9) rotate(3deg);
    background: radial-gradient(circle farthest-corner at 10% 20%, #b42424 17.8%, #DC9424 100.2%);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: rgb(32, 40, 61);
    padding: 20px;
    color: white;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  .modal-content h3 {
    margin-top: 20px;
  }
  
  .close-button-lista {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }
  
  .modal-profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 20px;
  }
  
  .download-link {
    display: block;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
  }
  
  .download-link:hover {
    text-decoration: underline;
  }
  
  .text-listaRipey {
    text-align: justify;
    padding-top: 30px;
    line-height: 1.8;
    font-size: 18px;
    color: white;
    padding-left: 50px;
  }
  
  .profesores-description {
    text-align: justify;
  }
  
  .map-container-ripey {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  
  @media (max-width: 1000px) {
    .map-container-ripey {
        padding-top: 20px; 
        padding-bottom: 20px; 
        padding-left: 0px; 
        padding-right: 0px; 
      }

    .professor-list {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .professor-card {
      width: 90%;
      height: auto;
      margin: 20px 0;
    }
  
    .profile-picture {
      width: 120px;
      height: 120px;
    }
  
    .professor-info h3 {
      font-size: 1em;
    }
  
    .styled-button {
      width: 100%;
      height: 35px;
      font-size: 12px;
    }
  
    .modal-content {
      max-width: 80%;
      width: 100%;
    }
  
    .modal-profile-picture {
      width: 120px;
      height: 120px;
    }
  
    .text-listaRipey {
      font-size: 16px;
      padding-left: 20px;
    }
  }
  