/* Conteneur principal */
.container-wrapper {
  display: flex;
  justify-content: center; /* Centre le conteneur horizontalement */
  align-items: center; /* Centre le conteneur verticalement */
  height: 120vh; /* Occupe toute la hauteur de la fenêtre */
  position: relative;
  overflow: hidden; /* Assure que les éléments ne débordent pas */
  margin: 0;
}

/* Conteneur pour les lettres alignées en colonne */
.yes-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre les lettres horizontalement */
  transition: transform 0.5s ease;
  position: relative;
}

/* Conteneur pour chaque lettre principale */
.letter-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

/* Style de base pour les lettres principales */
.letter-y, .letter-e, .letter-s {
  font-size: 100px;
  top: -145px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  position: relative;
}

/* Style des petites lettres animées */
.wave {
  display: inline-block;
  color: white;
  opacity: 0;
  font-size: 50px;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Positionnement des vagues */
.wave-1 { left: 55px;top: -60px}
.wave-2 { left: 90px; top: -60px }
.wave-3 { left: 125px; top: -60px }
.wave-spagnol.wave-1 { left: 55px;top: -60px}
.wave-spagnol.wave-2 { left: 88px; top: -60px}
.wave-spagnol.wave-3 { left: 125px; top: -60px}
.wave-spagnol.wave-4 { left: 160px; top: -60px}
.wave-spagnol.wave-5 { left: 195px; top: -60px}
.wave-spagnol.wave-6 { left: 230px; top: -60px  }
.wave-spagnol.wave-7 { left: 265px; top: -60px }
.wave-alsa.wave-1 { left: 55px; top: -60px }
.wave-alsa.wave-2 { left: 90px; top: -60px }
.wave-alsa.wave-3 { left: 120px; top: -60px }
.wave-alsa.wave-4 { left: 155px; top: -60px }

/* Classe visible */
.wave.visible {
  opacity: 1;
  transform: translateY(-50%) translateX(10px);
}

/* Style pour le conteneur de la croix de fermeture */
.close-btn {
  position: absolute;
  top: 0px;
  right: 0px; /* Positionne la croix à droite du conteneur principal */
  font-size: 30px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
}

.close-btn.visible {
  opacity: 1;
}

.details-container-Y {
  position: absolute;
  top: 50%; 
  transform: translateY(-50%); 
  right: -10px;
  padding: 20px; 
  border-radius: 8px; 
  text-align: center; /* Centre le texte dans les détails */
  transition: opacity 0.5s ease; 
}
  


  .details-container-E {
    position: absolute;
  top: 50%; 
  transform: translateY(-60%); 
  right: -10px;
  padding: 20px; 
  border-radius: 8px; 
  text-align: center; /* Centre le texte dans les détails */
  transition: opacity 0.5s ease; 
  }

  .details-container-S{
    position: absolute;
    top: 50%; 
    transform: translateY(-65%); 
    right: -10px;
    padding: 20px; 
    border-radius: 8px; 
    text-align: center; /* Centre le texte dans les détails */
    transition: opacity 0.5s ease; 
  }

.course-details {
  font-size: 24px;
  color: #000; /* Couleur de texte pour les détails */
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1000px){
  
.details-container-Y {
  top: 50% !important;
}
  


  .details-container-E {
    top: 50% !important; 
  
  }
}

@media (max-width: 900px){

  .container-wrapper {
    padding-bottom: 30%;
  }
  
  .details-container-Y {
    top: 45% !important;
  }
  
    .details-container-E {
      top: 40% !important;
    }

    .details-container-S {
      top: 40% !important;
    }
  }

  @media (max-width: 700px){

    .container-wrapper {
      padding-bottom: 100%;
    }
  
    .details-container-Y {
      top: 48% !important;
    }
    
      .details-container-E {
        top: 38% !important;
      }
  
      .details-container-S {
        top: 38% !important;
      }
    }

    @media (max-width: 500px){

      .container-wrapper {
        padding-bottom: 110%;
        height: 160vh;
      }
    
      .details-container-Y {
        top: 52% !important;
      }
      
        .details-container-E {
          top: 47% !important;
        }
    
        .details-container-S {
          top: 40% !important;
        }
      }


@media (max-width: 1300px) {
  .letter-y, .letter-e, .letter-s {
    font-size: 80px; /* Réduction de la taille de la police */
    top: -120px; /* Ajustement de la position verticale */
  }

  /* Réduction de la taille des petites lettres animées */
  .wave {
    font-size: 40px;
    top: 50%; /* Ajustement de la position pour les petites lettres */
  }

  /* Ajustement des positions des vagues pour éviter les débordements */
  .wave-1 { left: 40px; top: -50px; }
  .wave-2 { left: 70px; top: -50px; }
  .wave-3 { left: 100px; top: -50px; }
  .wave-spagnol.wave-1 { left: 0px; top: -50px; }
  .wave-spagnol.wave-2 { left: 20px; top: -50px; }
  .wave-spagnol.wave-3 { left: 60px; top: -50px; }
  .wave-spagnol.wave-4 { left: 80px; top: -50px; }
  .wave-spagnol.wave-5 { left: 110px; top: -50px; }
  .wave-spagnol.wave-6 { left: 130px; top: -50px; }
  .wave-alsa.wave-1 { left: 0px; top: -50px; }
  .wave-alsa.wave-2 { left: 40px; top: -50px; }
  .wave-alsa.wave-3 { left: 80px; top: -50px; }
  .wave-alsa.wave-4 { left: 100px; top: -50px; }

  /* Ajustement des conteneurs de détails pour un affichage correct */
  .details-container-Y, .details-container-E, .details-container-S {
    top: 45%; /* Ajuste la position verticale */
    transform: translateY(-50%); /* Centrer les détails correctement */
    padding: 15px; /* Réduction des marges */
    font-size: 18px; /* Ajustement de la taille du texte */
  }

  /* Réduction de la taille de la croix de fermeture */
  .close-btn {
    font-size: 24px; /* Réduction de la taille de la croix */
    top: 10px;
    right: 10px;
  }

  /* Ajustement du conteneur principal */
  .yes-container {
    transform: translateX(0); /* Empêche tout débordement horizontal */
  }

  /* Si le conteneur se déplace sur X, réduire son déplacement pour les petits écrans */
  .yes-container[style*="translateX(-650px)"] {
    transform: translateX(-300px); /* Réduction du déplacement horizontal */
  }
}




@media (max-width: 1500px) {
  .letter-y, .letter-e, .letter-s {
    font-size: 80px; /* Réduction de la taille de la police */
    top: -120px; /* Ajustement de la position verticale */
    left: 0;
  }

  /* Réduction de la taille des petites lettres animées */
  .wave {
    font-size: 40px;
    top: 50%; /* Ajustement de la position pour les petites lettres */
  }

  /* Ajustement des positions des vagues pour éviter les débordements */
  .wave-1 { left: 40px; top: -50px; }
  .wave-2 { left: 70px; top: -50px; }
  .wave-3 { left: 80px; top: -50px; }
  .wave-spagnol.wave-1 { left: 0px; top: -50px; }
  .wave-spagnol.wave-2 { left: 0px; top: -50px; }
  .wave-spagnol.wave-3 { left: 0px; top: -50px; }
  .wave-spagnol.wave-4 { left: 0px; top: -50px; }
  .wave-spagnol.wave-5 { left: 0px; top: -50px; }
  .wave-spagnol.wave-6 { left: 0px; top: -50px; }
  .wave-alsa.wave-1 { left: -10px; top: -50px; }
  .wave-alsa.wave-2 { left: -10px; top: -50px; }
  .wave-alsa.wave-3 { left: -10px; top: -50px; }
  .wave-alsa.wave-4 { left: -10px; top: -50px; }

  /* Ajustement des conteneurs de détails pour un affichage correct */
  .details-container-Y, .details-container-E, .details-container-S {
    top: 45%; /* Ajuste la position verticale */
    transform: translateY(-50%); /* Centrer les détails correctement */
    padding: 15px; /* Réduction des marges */
    font-size: 18px; /* Ajustement de la taille du texte */
  }

  /* Réduction de la taille de la croix de fermeture */
  .close-btn {
    font-size: 24px; /* Réduction de la taille de la croix */
    top: 10px;
    right: 10px;
  }

  /* Ajustement du conteneur principal */
  .yes-container {
    transform: translateX(0); /* Empêche tout débordement horizontal */
  }

  /* Si le conteneur se déplace sur X, réduire son déplacement pour les petits écrans */
  .yes-container[style*="translateX(-650px)"] {
    transform: translateX(-300px); /* Réduction du déplacement horizontal */
  }
}

