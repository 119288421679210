.searchBar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
}

.group {
  display: flex;
  align-items: center;
  position: relative;
}

.input {
  width: 230px;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid #8e8e8e;
  border-radius: 8px;
  outline: none;
  background-color: #21140e;
  color: white;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #ffffff;
}

.input:focus,
.input:hover {
  outline: none;
  border-color: #8e8e8e;
  background-color: #21140e;
  box-shadow: 0 0 0 4px rgb(180 36 36 / 10%);
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #8e8e8e;
  width: 1rem;
  height: 1rem;
}

@media (max-width: 768px) {
  .searchBar-container {
    flex-direction: column; 
    gap: 5px;
    margin-top: 5px;
  }

  .input {
    width: 100%; 
    max-width: 300px; 
    height: 35px; 
  }

  .icon {
    left: 0.8rem; 
    width: 0.8rem;
    height: 0.8rem;
  }
}
