/* Styles pour la modale principale */
.modal-paiement {
    width: fit-content;
    height: fit-content;
    background: #ffffff;
    border-radius: 26px;
    max-width: 500px;
    position: relative;
    z-index: 10; /* Assure que la modal principale est sous la confirmation */
  }
  
  /* Formulaire de paiement */
  .form-paiement {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  /* Options de paiement */
  .payment--options-paiement {
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: 33% 34% 33%;
    gap: 20px;
    padding: 10px;
  }
  
  .payment--options-paiement button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    background: #f2f2f2;
    border-radius: 11px;
    padding: 0;
    border: 0;
    outline: none;
    transition: transform 0.2s ease-in-out;
  }
  
  .payment--options-paiement img {
    height: 24px;
    width: auto;
  }
  
  .payment--options-paiement-google img {
    height: 41px;
    width: auto;
  }
  
  /* Séparateur (OR) */
  .separator-paiement {
    width: calc(100% - 20px);
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 10px;
    color: #8b8e98;
    margin: 0 10px;
  }
  
  .separator-paiement > p {
    word-break: keep-all;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 11px;
    margin: auto;
  }
  
  .separator-paiement .line-paiement {
    display: inline-block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e8e8e8;
    margin: auto;
  }
  
  /* Formulaire des informations de la carte de crédit */
  .credit-card-info--form-paiement {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input_container-paiement {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .split-paiement {
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 15px;
  }
  
  .input_label-paiement {
    font-size: 10px;
    color: #8b8e98;
    font-weight: 600;
  }
  
  .input_field-paiement {
    width: auto;
    height: 40px;
    padding: 0 0 0 16px;
    border-radius: 9px;
    outline: none;
    background-color: #f2f2f2;
    border: 1px solid #e5e5e500;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .input_field-paiement:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
  }
  
  /* Bouton de paiement */
  .purchase--btn-paiement {
    height: 55px;
    background: linear-gradient(180deg, #363636 0%, #1b1b1b 50%, #000000 100%);
    border-radius: 11px;
    border: 0;
    outline: none;
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #000000;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .purchase--btn-paiement:hover {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0000003a;
  }
  
  /* Reset input number styles */
  .input_field-paiement::-webkit-outer-spin-button-paiement,
  .input_field-paiement::-webkit-inner-spin-button-paiement {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .input_field-paiement[type=number] {
    -moz-appearance: textfield;
  }
  
  /* Bouton d'annulation */
  .cancel--btn-paiement {
    margin-top: 20px;
    width: 100%;
    height: 45px;
    background: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .cancel--btn-paiement:hover {
    background-color: #e5e5e5;
    color: #000;
  }
  
  /* Modale de confirmation */
  .confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000; /* Met la confirmation au-dessus des autres éléments */
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Texte dans la modale de confirmation */
  .confirmation-modal p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Boutons dans la modale de confirmation */
  .confirmation-buttons {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  .confirm--btn-paiement,
  .cancel--btn-confirm-paiement {
    width: 50px;
    height: 40px;
    background: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    margin-right: 10px;
  }
  
  .confirm--btn-paiement:hover,
  .cancel--btn-confirm-paiement:hover {
    background-color: #e5e5e5;
    color: #000;
  }
  
  /* Fond flouté quand la confirmation est active */
  .blur-background {
    filter: blur(5px);
    pointer-events: none; /* Désactive les interactions avec le fond */
    z-index: 5; /* Pour que le fond flouté reste derrière la modale de confirmation */
  }
  