/* Definir la animación del borde */
@keyframes borderPulse {
    0% {
        border-color: #ffffffd1;
        /* Color inicial */
        box-shadow: 0 0 5px 0 #ffffffd1;
    }

    50% {
        border-color: #b52125 ;
        /* Color intermedio */
        box-shadow: 0 0 15px 5px #b52125; 
    }

    100% {
        border-color: #ffffffd1;
        /* Color final */
        box-shadow: 0 0 5px 0 #ffffffd1;
    }
}

.isla-dinamica {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 40px;
    width: auto;
    min-width: 140px;
    max-width: 70vw;
    height: auto;
    background-color: #000000;
    border-radius: 40px;
    z-index: 1000;
    /* Propiedades del borde para animación */
    border: 3.5px solid #ffffffd1;
    /* Color inicial del borde */
    animation: borderPulse 2s infinite ease-in-out;
    /* Aplicar la animación */
    box-shadow: 0 -1px 1px .1px #ffffffd1, 0 -1px 4px 1px #af23ff85, 0 -1px 25px 4px #af23ff70;
    /* Sombras del borde */
}



.shape {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background-color: black;
}

.box {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    height: 50px;
}

.btn {
    background-color: #000000;
    border: none;
    color: white;
    padding: 4px 5.33px;
    font-size: 14px;
    cursor: pointer;
}

.isla-dinamica-left,
.isla-dinamica-right {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2%;
    width: 100%;
}

.isla-dinamica-left {
    align-items: center;
}

.isla-dinamica-right {
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.isla-dinamica-middle {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-name {
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    text-align: center;
    width: auto; /* Permite que el contenido determine el tamaño */
  }
  
.isla-dinamica.show-name .section-name {
    animation: scroll 10s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.isla-dinamica-left {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
}

.no-link {
    color: inherit;
    text-decoration: none;
}

.no-link:hover {
    text-decoration: none;
    color: inherit;
}

.menu h3 {
    margin: 0;
    margin-bottom: 20px;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
}

.menu ul li {
    margin-bottom: 10px;
    overflow: hidden;
}

.menu ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 10px;
    background-color: #000000;
    border-radius: 5px;
    text-align: center;
}

.menu ul li a:hover {
    background-color: #555;
}


.isla-dinamica {
    transition: height 0.3s ease;
}

.menu {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20PX;
    height: auto;
    background-color: #000000;
    animation: aparicion 0.1s ease-out 0s 1;
    transition: all 0.4s ease;
    border-radius: 30px;
    z-index: 1000;
    box-shadow: 0 -1px 1px .1px #ffffffd1, 0 -1px 4px 1px rgba(217,149,38,255), 0 -1px 25px 4px rgba(217,149,38,255);
    transition: width 4s ease-in, height 4s ease;
    box-sizing: border-box;
    overflow: hidden;
    width: auto;
}

@keyframes aparicion {
    from {
        opacity: 1;
        transform: translateX(-50%) scale(0.8);
    }

    to {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}


/* Ajustes más estrictos para teléfonos pequeños */
@media (max-width: 550px) {
    .isla-dinamica-left, .isla-dinamica-right {
        font-size: 13px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
