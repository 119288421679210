.signupPage {
    background-color: #ffffff;  
    min-height: 100vh;         
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    padding: 20px;
}

.authMenu {
    position: absolute;
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
}
