.ripey-container {
  padding: 50px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  color: #ffffff;
  font-size: 18px;
}

.ripey-title {
  font-size: 2em;
  color: #ffffff;
  margin-bottom: 10px;
}

.ripey-intro {
  font-size: 1.2em;
  font-weight: bold;
  color: #8e8e8e;
  margin-bottom: 20px;
}

.card-container {
  position: relative;
  width: 700px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.card-container::before {
  content: '';
  background-color: #181818;
  position: absolute;
  z-index: 1;
  transition: 0.3s ease;
  height: 98%;
  width: 98%;
  top: 1%;
  left: 1%;
  border-radius: 28px;
}

.card-container:hover {
  box-shadow: 0px 0px 30px #b42424;
}

.ripey-list {
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.ripey-list li {
  margin-bottom: 10px;
}

.ripey-note-title {
  font-size: 1.2em;
  color: #8e8e8e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ripey-formation-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #dcdcdc;
  border: 1px solid #ffffff; /* Légère bordure blanche autour de la section */
  padding: 10px; 
  border-radius: 8px; 
}

.ripey-formation-list li {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .ripey-container {
    padding: 20px;
    font-size: 16px;
    text-align: justify;
  }

  .ripey-title {
    font-size: 1.6em;
    margin-bottom: 5px;
  }

  .ripey-intro {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  .card-container {
    width: 90%;
    padding: 15px;
  }

  .ripey-list {
    margin: 15px 0;
  }

  .ripey-list li {
    font-size: 14px;
  }

  .ripey-note-title {
    font-size: 1.1em;
  }

  .ripey-formation-list {
    font-size: 1em;
  }
}
