
/* Menu Container */
.menu-iky {
    position: relative; /* Position relative pour le conteneur parent */
    width: 100%;
    height: 100vh; /* Hauteur de la vue pour centrer verticalement */
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de se placer en plusieurs lignes */
    justify-content: center;
    align-items: center;
}

/* Menu Items */
.menu-item-iky {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #463d4b;
  filter: drop-shadow(0px 0px 30px #DC9424);
  border-radius: 2px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease all;
}
.menu-item-iky:hover {
  filter: drop-shadow(0px 0px 30px #463d4b);
}

/* Positions spécifiques pour les carrés */
.menu-item-iky:nth-child(9) { margin-top: 90px; margin-left: -337px; width: 132px; height: 132px; background-color: #463d4b;}
.menu-item-iky:nth-child(10) { margin-top: -299px; margin-left: 398px; width: 132px; height: 132px; background-color: #463d4b;}
.menu-item-iky:nth-child(11) { margin-top: -302px; margin-left: 793px; width: 132px; height: 132px;background-color: #463d4b;}
.menu-item-iky:nth-child(8) { margin-top: 87px; margin-left: -731px; width: 132px; height: 132px;background-color: #463d4b;}
.menu-item-iky:nth-child(6) { margin-top: 94px; margin-left: 795px; width: 132px; height: 132px;background-color: #463d4b;}
.menu-item-iky:nth-child(7) { margin-top: -309px; margin-left: -730px; width: 132px; height: 132px; background-color: #463d4b;}
.menu-item-iky:nth-child(5) { margin-top: -103px; margin-left: 30px; width: 250px; height: 250px; background-color: #463d4b;}
.menu-item-iky:nth-child(4) { margin-top: -109px; margin-left: -929px; width: 132px; height: 132px; background-color: #463d4b;}
.menu-item-iky:nth-child(2) { margin-top: -104px; margin-left: 596px; width: 132px; height: 132px;background-color: #463d4b;}
.menu-item-iky:nth-child(3) { margin-top: -106px; margin-left: 991px; width: 132px; height: 132px;background-color: #463d4b;} /* Exemple de nouveau carré décoratif */
.menu-item-iky:nth-child(1) { margin-top: -110px; margin-left: -532px; width: 132px; height: 132px;background-color: #463d4b;} 
/* Exemple de nouveau carré décoratif */
/* Menu Item Inner */
.menu-item-inner-iky { 
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #fff; /* Couleur du texte */
  transform: rotate(45deg); /* Applique une rotation de 45 degrés */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Animation fluide pour le texte */ /* Applique une rotation de 45 degrés */
}

.with-text {
  color: #ffffff;
  background-color: #463d4b;
}

/* Styles spécifiques aux éléments avec effet */
.special-effect {
  transition: 0.3s ease all;
}

.special-effect:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #ffffff;
  z-index: -1;
  border-radius: 2px;
}

.special-effect:hover .menu-item-inner-iky {
  color: #463d4b;
}

.special-effect:hover:before {
  left: 0;
  right: 0;
  opacity: 1;
  background-color: #ffffff;
}

/* Effet de clic */
.with-text:active {
  transform: scale(0.9);
}
  
