/* Animation fade-in */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .yoga-definition {
    text-align: center;
    margin-bottom: 30px;
    animation: fadeIn 2s ease-in-out; /* Animation sur 2 secondes */
  }
  
  .yoga-definition h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .yoga-description {
    font-size: 1.2rem;
    line-height: 1.6;
    padding-top: 50px;
    color: white;
    max-width: 800px;
    margin: 0 auto;
    animation: fadeIn 2s ease-in-out; /* Animation synchronisée */
  }
  