.loginPage {
    min-height: 100vh;         
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    padding: 20px;
    position: relative; /* Ensure that child elements can be positioned relative to this container */
}

.authMenu {
    position: absolute;
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
}
