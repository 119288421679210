.explanation-aire {
    position: relative;
    border-radius: 8px;
    width: 100%; /* Par défaut, occupe toute la largeur */
    max-width: 630px; /* Largeur maximale sur les grands écrans */
    margin: 0 auto; /* Centré */
    overflow: hidden; 
}

.explanation-aire::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/elements/Aire1.png");
    opacity: 1; /* Transparence de l'image de fond */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; /* Met l'image derrière le contenu */
    transition: opacity 0.3s ease;
}

.explanation-aire h4 {
    color: #bfbfbf;
    font-size: 1.5rem;
    margin-top: -10px;
    text-align: center;
    text-transform: uppercase;
    position: relative; /* Assure que le texte est au-dessus de l'image */
    z-index: 1;
}

.explanation-aire p {
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.6;
    position: relative; /* Assure que le texte est au-dessus de l'image */
    z-index: 1;
}

/* ---- MEDIA QUERIES ---- */

/* Pour les petits écrans */
@media (max-width: 768px) {
    .explanation-aire {
        max-width: 85%; /* Limite la largeur à 90% de l'écran */
        border-radius: 6px; /* Coins légèrement arrondis */
    }

    .explanation-aire h4 {
        font-size: 1.2rem; /* Réduit la taille de la police du titre */
    }

    .explanation-aire p {
        font-size: 0.9rem; /* Réduit la taille des paragraphes */
    }
}

/* Pour les très petits écrans (mobiles en portrait) */
@media (max-width: 480px) {
    .explanation-aire {
        padding: 10px; /* Réduit encore l'espace intérieur */
    }

    .explanation-aire h4 {
        font-size: 1rem; /* Encore plus petit pour le titre */
    }

    .explanation-aire p {
        font-size: 0.8rem; /* Texte plus petit pour les paragraphes */
        line-height: 1.4; /* Réduit l'espacement des lignes */
    }
}

/* Pour les grands écrans */
@media (min-width: 1200px) {
    .explanation-aire {
        max-width: 670px; 
    }

    .explanation-aire h4 {
        font-size: 1.8rem; /* Agrandit le titre */
    }

    .explanation-aire p {
        font-size: 1.1rem; /* Texte légèrement plus grand */
    }
}
