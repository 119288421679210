.modal-overlay-pdf {
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;
}

.modal-content-pdf {
  position: relative;
  width: 80%;
  max-width: 1000px;
}

.modal-close-pdf  {
  position: absolute;
  top: 10px;
  right: 0px;
  border: none;
  background: transparent;
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.modal-close-pdf :hover {
  background: #e6005c;
}
