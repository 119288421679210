.container {
    width: 100%;
    margin: 0 auto;
}

.content-wrapper {
    display: flex;
    align-items: flex-start; 
    justify-content: flex-start;
    width: 100%;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    flex-wrap: wrap; /* Permettre aux éléments de s'empiler sur des lignes multiples */
}

.title-container {
    margin-bottom: 10px;
}

.title {
    color: rgb(117, 37, 37);
    font-size: 45px;
    text-align: center;
    margin: 20px auto; /* Centrer le titre */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.text-container {
    flex: 1;
    background-color: rgb(246, 246, 246);
    box-sizing: border-box;
    text-align: justify;
    line-height: 2;
    padding: 20px 60px;
    margin-bottom: 20px; /* Espace en bas de la section texte */
    border-radius: 5px;
}

.image-container {
    flex: 1;
    margin-left: 20px;
    margin-right: 50px;
    margin-bottom: 20px; /* Espace en bas de l'image */
}

.image-container img {
    max-width: 100%;
    height: auto; /* Ajuste la hauteur automatiquement */
    display: block;
    border-radius: 5px;
}
