.modal-overlay-nuestroProfesores {
  position: fixed; /* Fixe la modale à la fenêtre, même lorsque l'utilisateur fait défiler */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Fond sombre semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  }
  
  .modal-content-nuestroProfesores {
    background: rgb(19, 18, 18);
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    position: relative;
    color: white;
    text-align: justify;
  }
  
  .modal-close-nuestroProfesores {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 1.5em;
    cursor: pointer;
    color : white; 
  }
  
  .modal-photo-nuestroProfesores {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .modal-name-nuestroProfesores {
    margin: 10px 0;
  }
  
  .modal-profession-nuestroProfesores {
    color: #666;
  }
  
  .modal-nuestroProfesores {
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .modal-content-nuestroProfesores {
      width: 90%;
      max-width: none;
      padding: 15px;
      font-size: 18px;
      margin: 10px;
    }
  
    .modal-photo-nuestroProfesores {
      width: 80px;
      height: 80px;
    }
  
    .modal-close-nuestroProfesores {
      font-size: 1.2em;
      top: 5px;
      right: 5px;
    }
  
    .modal-name-nuestroProfesores {
      font-size: 1em;
    }
  
    .modal-profession-nuestroProfesores {
      font-size: 0.8em;
    }
  
    .modal-nuestroProfesores {
      margin-top: 5px;
    }
  }
  