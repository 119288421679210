.biographie-container {
  display: flex;
  padding: 50px;
  max-width: 100%;
  margin: 0 auto;
}

.biographie-photo {
  border-radius: 5px;
  width: 250px;
  height: 350px;
  object-fit: cover;
  margin-bottom: 16px;
  padding: 7px; /* Ajoute de l'espace entre la photo et le cadre */
  border: 1px solid rgb(133, 129, 129); /* Ajoute un cadre noir autour de la photo */
  background-color: rgb(49 67 26); 
  margin-right: 6dvb;
}

.biographie-text {
  flex: 1;
  color: white;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.biographie-nom {
  margin: 0;
  font-size: 1.6em;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.biographie-titre {
  font-size: 1.3em;
  color: #ffffff;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.biographie-presentation {
  margin: 8px 0 0;
}

.biographie-section-title{
  color: white;
}

@media (max-width: 768px) {
  .biographie-container {
    flex-direction: column; /* Change l'orientation des éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    padding: 1px; /* Réduit le padding sur les petits écrans */
  }

  .biographie-photo {
    margin-right: 0; /* Supprime l'espace droit */
    margin-bottom: 20px; /* Ajoute de l'espace sous la photo */
    width: 60%; /* L'image prend 80% de la largeur */
    height: auto; /* Ajuste la hauteur proportionnellement à la largeur */
  }

  .biographie-text {
    color: white;
    font-size: 1em.1;
    line-height: 1.8;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .biographie-nom {
    font-size: 1.6em; /* Réduit la taille du nom */
  }

  .biographie-titre {
    font-size: 1.6em; /* Réduit la taille du titre */
  }

  .biographie-section-text {
    font-size: 1.2em; 
    text-align: justify;/* Réduit la taille du texte de la section */
  }

  .biographie-section-title{
    color: white;
    font-size: 1.4;
  }
}