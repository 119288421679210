/* Styles pour le conteneur des catégories de sport */
.sports-categories {
    display: flex;
    gap: 20px;
    padding: 20px;
    justify-content: center;
  }
  
  /* Styles pour chaque cadre de catégorie */
  .category-frame {
    position: relative;
    width: 200px;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    background-color: none;
  }
  
  /* Styles pour les images des catégories */
  .category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
    z-index: 1000;
    transition: opacity 0.3s;
  }
  
  /* Styles pour l'overlay de la catégorie */
  .category-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.3s;
  }
  
  /* Effet au survol : rendre l'image plus visible */
  .category-frame:hover .category-image {
    opacity: 1;
  }
  
  /* Effet au survol : rendre le texte plus visible */
  .category-frame:hover .category-overlay {
    background: rgba(0, 0, 0, 0.7);
  }
  