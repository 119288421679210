.map-description-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.map-section {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  margin-top: 80px;
  margin-bottom: 0px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%; /* Assurez-vous que la carte prend toute la largeur disponible */
  max-width: 100%; /* Eviter le dépassement horizontal */
}

.description-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 60px;
  margin-bottom: 0px;
  box-sizing: border-box;
}

.description-section h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #ffffff;
}

.description-section p {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.;
  color: #ffffff;
  margin-bottom: 15px;
  text-align: justify;
}

.description-section strong {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .map-description-container {
    flex-direction: column;
    padding: 10px;
  }

  .map-section,
  .description-section {
    flex: 1 1 100%;
    padding: 10px;
    width: 100%; /* Assurez-vous que les sections prennent toute la largeur */
    max-width: 100%;
  }

  .description-section h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .description-section p,
  .description-section strong {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: justify;
  }

  .map-section {
    margin-top: 40px;
  }

  .description-section {
    margin-top: 40px;
  }
}
