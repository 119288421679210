/* Style de base */
.explanation-tierra {
    position: relative;
    border-radius: 8px;
    padding: 20px;
    width: 100%; /* Par défaut, occupe toute la largeur */
    max-width: 630px; /* Largeur maximale */
    margin: 0 auto; /* Centré */
    overflow: hidden; /* Empêche l'image de dépasser les bords arrondis */
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.explanation-tierra::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/elements/tierra1.png");
    opacity: 0.8; /* Transparence de l'image de fond */
    background-size: cover; /* L'image couvre tout l'élément */
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; /* L'image est derrière le contenu */
    transition: opacity 0.3s ease;
}

.explanation-tierra h4 {
    color: #413e3a;
    font-size: 1.5rem;
    margin-top: -10px;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    position: relative; /* Assure que le texte est au-dessus de l'image */
    z-index: 1;
}

.explanation-tierra p {
    color: white;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.explanation-tierra p strong {
    color: white;
    font-weight: bold;
}

/* ---- MEDIA QUERIES ---- */

/* Pour les petits écrans (≤768px) */
@media (max-width: 768px) {
    .explanation-tierra {
        padding: 15px; /* Réduit l'espace intérieur */
        max-width: 90%; /* Limite la largeur à 90% de l'écran */
        border-radius: 6px; /* Coins légèrement arrondis */
    }

    .explanation-tierra h4 {
        font-size: 1.2rem; /* Réduit la taille de la police du titre */
    }

    .explanation-tierra p {
        font-size: 0.9rem; /* Réduit la taille des paragraphes */
    }
}

/* Pour les très petits écrans (≤480px) */
@media (max-width: 480px) {
    .explanation-tierra {
        padding: 10px; /* Réduit encore l'espace intérieur */
    }

    .explanation-tierra h4 {
        font-size: 1rem; /* Taille du titre plus petite */
    }

    .explanation-tierra p {
        font-size: 0.8rem; /* Taille des paragraphes réduite */
        line-height: 1.4; /* Réduit l'espacement des lignes */
    }
}

/* Pour les grands écrans (≥1200px) */
@media (min-width: 1200px) {
    .explanation-tierra {
        max-width: 700px; /* Augmente la largeur pour les écrans larges */
        padding: 25px; /* Augmente l'espace intérieur */
    }

    .explanation-tierra h4 {
        font-size: 1.8rem; /* Agrandit la taille du titre */
    }

    .explanation-tierra p {
        font-size: 1.1rem; /* Augmente la taille des paragraphes */
    }
}
