/* Conteneur du Menu */
.menu-even {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Éléments du Menu */
.menu-item-even {
  position: absolute;
  filter: drop-shadow(0px 0px 30px #DC9424);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4b1a1a;
  border-radius: 2px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease all;
}

.menu-item-even:hover {
  filter: drop-shadow(0px 0px 30px #4b1a1a);
}

.menu-item-inner-even {
  font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff; /* Couleur du texte */
    transform: rotate(45deg); /* Applique une rotation de 45 degrés */
    transition: color 0.3s ease, text-shadow 0.3s ease; 
}

/* Styles pour les carrés avec texte */
.with-text {
  color: #ffffff;
  background-color: #4b1a1a;
}

/* Styles spécifiques aux éléments avec effet */
.special-effect {
  /* Ajouter ici les styles pour les éléments avec effets */
  transition: 0.3s ease all;
}

.special-effect:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #ffffff;
  z-index: -1;
  border-radius: 2px;
}

.special-effect:hover .menu-item-inner-even {
  color: #4b1a1a;
}

.special-effect:hover:before {
  left: 0;
  right: 0;
  opacity: 1;
  background-color: #ffffff;
}

/* Effet de clic */
.with-text:active {
  transform: scale(0.9);
}

/* Positions spécifiques pour les carrés */
.menu-item-even:nth-child(7) { margin-top: 168px; margin-left: -214px; width: 100px; height: 100px; background-color: #4b1a1a;}
.menu-item-even:nth-child(8) { margin-top: 410px; margin-left: 31px; width: 100px; height: 100px; background-color: #4b1a1a;}
.menu-item-even:nth-child(6) { margin-top: 157px; margin-left: 287px; width: 100px; height: 100px;background-color: #4b1a1a; }
.menu-item-even:nth-child(2) { margin-top: 4px; margin-left: 482px; width: 132px; height: 132px;background-color: #4b1a1a; }
.menu-item-even:nth-child(4) { margin-top: 7px; margin-left: 869px; width: 132px; height: 132px; background-color: #4b1a1a;}
.menu-item-even:nth-child(1) { margin-top: -103px; margin-left: 30px; width: 250px; height: 250px;background-color: #4b1a1a; }
.menu-item-even:nth-child(5) { margin-top: 15px; margin-left: -798px; width: 132px; height: 132px; background-color: #4b1a1a;}
.menu-item-even:nth-child(3) { margin-top: 16px; margin-left: -412px; width: 132px; height: 132px; background-color: #4b1a1a;}


/* Enlever les effets pour "Eventos" */
.menu-item-even:nth-child(9) {
  background-color: #4b1a1a;
  border: 3px solid rgb(181, 78, 78);
  box-shadow: none; /* Enlever les ombres */
  cursor: default;
 /* Supprimer le curseur pointer */
  /* Aucun effet de transition */
}
