.event-detail {
  position: relative;
  width: 100%;
  height: 100vh; /* Pour occuper toute la hauteur de la fenêtre */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

/* Cadre contenant les détails de l'événement */
.event-info-container {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 30px;
  width: 500px;
  max-width: 90%;
  text-align: justify;
  font-size: 16px;
  color: rgb(255, 255, 255);
  transition: color 0.3s ease-in-out;
  margin-left: 40px;
}

.event-info-container h1 {
  color: white;
}

/* Changement de style après défilement */
.event-info-container.scrolled {
  color: rgb(154, 154, 154);
  z-index: 1000; /* Change la couleur du texte en noir */
}

.event-info-container.scrolled h1 {
  color: black;
}

.content-below {
  height: 80vh; /* Hauteur de la section avec le fond blanc */
  padding: 50px 20px;
  background-color: rgb(206, 206, 206);
  position: relative; /* Assure que les éléments positionnés absolument sont relatifs à ce conteneur */
}

.carousel-event{
  position: absolute;
  width: 30vw;
  right: 10%;
}

.flyer-image {
  height: 100%;
}

.slider-container {
  height: 80vw; /* Hauteur de la section avec le fond blanc */
  width: auto;
  right: 0;
  left: 50%;
  position: relative;
}

.slider-container .slick-slide {
  display: flex;
}


/* Styles pour le bouton d'inscription */
.register-button {
  position: absolute;
  bottom: 20%;
  left: 20%;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #B42424; /* Couleur de fond du bouton */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #B42424; /* Couleur de fond au survol du bouton */
}

/* Style de la fenêtre modale */
.modal-event {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  z-index: 1000;
}

/* Style du contenu de la fenêtre modale */
.modal-event-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 800%;
  max-width: 500px;
  animation: modal-fade-in 0.3s ease-out;
}

/* Animation d'apparition de la modale */
@keyframes modal-fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Bouton pour fermer la modale */
.close-button {
  background-color: #ff5c5c;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Mobile et petits écrans */
@media (max-width: 768px) {
  /* Conteneur principal */
  .event-detail {
    flex-direction: column;
    background-image: none !important;
    height: 50vh;
  }

  .content-below{
    display: flex;
    justify-content: center;
    height: auto;
  }

  .carousel-event {
    position: relative;
    width: 80%;
    right: 0;
  }

  .event-info-container {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 20px;
    border-radius: 10px;
    left: 5%;
    width: 80%;
    margin: 0;
    position: relative;
  }

  .event-info-container h1 {
    font-size: 1.6em;
    margin-bottom: 8px;
    color: rgb(0, 0, 0);
  }
  


  .event-info-container p {
    font-size: 16px;
    color: black;
  }

  .register-button {
    position: relative;
        width: 100%;
        max-width: 250px;
        padding: 10px;
        font-size: 14px;
        bottom: -35px;
        left: 0;
  }
  .button{
    display: flex;
    justify-content: center;
  }
}
