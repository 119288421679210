.container-diplomas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-exemple {
  margin-top: 50px;
  margin-bottom: 40px;
  max-width: 500px;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .container-diplomas {
    flex-direction: column;
  }

  .img-exemple {
    max-width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
