.yoga-summary {
  padding: 20px;
  margin: 50px auto;
  margin-top: 120px;
  margin-bottom: 130px;
  width: 80%;
  max-width: 1000px;
  font-family: Arial, sans-serif;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.yoga-summary.visible {
  opacity: 1;
  transform: translateY(0);
}

.h2-yoga {
  text-align: center;
  color: #333;
}

.yoga-methods,
.session-details {
  margin-top: 20px;
}

.yoga-methods h3,
.session-details h3 {
  color: #555;
}

.session-details-yoga ul {
  list-style-type: none;
}

.li-yoga {
  margin-bottom: 10px;
  
}

.p-yoga {
  line-height: 1.6;
  color: #666;
}

@media (max-width: 1300px) {
  .yoga-summary {
      width: 90%; /* Rétrécissement de la largeur */
      min-height: 80%; /* Ajout d'une hauteur minimale pour s'assurer d'un bon affichage */
      padding: 20px 10px; 
      margin-top: 70px;
      margin-bottom: 0px;
  }

  .h2-yoga {
      font-size: 1.3rem;
  }

  .li-yoga{
    font-size: 1rem;
  }

  .yoga-methods h3,
  .session-details h3 {
      font-size: 1.1rem;
  }

  .p-yoga {
      font-size: 1rem; /* Légère réduction de la taille du texte */
  }
}



@media (max-width: 1300px) {
  .yoga-summary {
      width: 80%; 
      min-height: 80%; /* Ajout d'une hauteur minimale pour s'assurer d'un bon affichage */
      padding: 20px 10px; 
      margin-top: 0px;
      margin-bottom: 0px;
  }

  .h2-yoga {
      font-size: 1.1rem;
  }

  .li-yoga{
    font-size: 0.9rem;
  }

  .yoga-methods h3,
  .session-details h3 {
      font-size: 1rem;
  }

  .p-yoga {
      font-size: 0.9rem; /* Légère réduction de la taille du texte */
  }
}



@media (max-width: 1000px) {
  .yoga-summary {
      width: 95%; 
      padding: 0px 15px; /* Ajout de plus de padding vertical */
      margin-top: 20%; /* Réduction de l'espace en haut */
      margin-bottom: 10%; /* Réduction de l'espace en bas */
  }

  .h2-yoga {
      font-size: 1.4rem; /* Réduction de la taille du titre principal */
  }

  .yoga-methods h3,
  .session-details h3 {
      font-size: 1.1rem; /* Réduction des sous-titres */
  }

  .p-yoga {
      font-size: 0.85rem; /* Réduction de la taille des paragraphes */
  }
}

@media (max-width: 700px) {
  .yoga-summary {
      width: 90%; 
      padding: 0px 15px; /* Ajout de plus de padding vertical */
      margin-top: 20%; /* Réduction de l'espace en haut */
      margin-bottom: 10%; /* Réduction de l'espace en bas */
  }

  .h2-yoga {
      font-size: 1.4rem; /* Réduction de la taille du titre principal */
  }

  .yoga-methods h3,
  .session-details h3 {
      font-size: 1.1rem; /* Réduction des sous-titres */
  }

  .p-yoga {
      font-size: 0.85rem; /* Réduction de la taille des paragraphes */
  }
}
