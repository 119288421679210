.carousel-container{
  background-color: #21140e;
}
.carousel-slide {
  display: flex;
  justify-content: center;
  
}

.carousel-image {
  max-width: 100%;
  height: auto;
}
