/* Menu Container */
.menu-ripey {
    position: relative; /* Position relative pour le conteneur parent */
    width: 100%;
    height: 100vh; /* Hauteur de la vue pour centrer verticalement */
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de se placer en plusieurs lignes */
    justify-content: center;
    align-items: center;
}

/* Menu Items */
.menu-item-ripey {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #20283D;
    filter: drop-shadow(0px 0px 30px #DC9424);

    border-radius: 2px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease all; /* Animation fluide */
}

.menu-item-ripey:hover {
  filter: drop-shadow(0px 0px 30px #20283D);
}

.menu-item-ripey:nth-child(6) { margin-top: 94px; margin-left: -332px; width: 132px; height: 132px; color: 20283D;}
.menu-item-ripey:nth-child(10) { margin-top: 94px; margin-left: 397px; width: 132px; height: 132px;color: 20283D;}
.menu-item-ripey:nth-child(7) { margin-top: -298px; margin-left: 399px; width: 132px; height: 132px;color: 20283D;}
.menu-item-ripey:nth-child(4) { margin-top: -103px; margin-left: 30px; width: 250px; height: 250px;color: 20283D;}
.menu-item-ripey:nth-child(5) { margin-top: -302px; margin-left: -332px; width: 132px; height: 132px;color: 20283D;}
.menu-item-ripey:nth-child(1) { margin-top: -105px; margin-left: -530px; width: 132px; height: 132px;color: 20283D;}
.menu-item-ripey:nth-child(3) { margin-top: -102px; margin-left: 597px; width: 132px; height: 132px;color: 20283D;}
.menu-item-ripey:nth-child(2) { margin-top: 413px; margin-left: 29px; width: 100px; height: 100px;color: 20283D;}

 
.menu-item-inner-ripey {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff; /* Couleur du texte */
    transform: rotate(45deg); /* Applique une rotation de 45 degrés */
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Animation fluide pour le texte */
}

.with-text-ripey {
    color: #ffffff;
    background-color: #20283D;
  }
  
  /* Styles spécifiques aux éléments avec effet */
  .special-effect {
    transition: 0.3s ease all;
  }
  
  .special-effect:before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: '';
    background-color: #ffffff;
    z-index: -1;
    border-radius: 2px;
  }
  
  .special-effect:hover .menu-item-inner-ripey {
    color: #20283D;
  }
  
  .special-effect:hover:before {
    left: 0;
    right: 0;
    opacity: 1;
    background-color: #ffffff;
  }
  
  /* Effet de clic */
  .with-text:active {
    transform: scale(0.9);
  }

 