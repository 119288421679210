.video-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-video {
  width: 15em;
  height: 10em;
  background: linear-gradient(270deg, #ce68d9, #45c6db, #45db79);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  transition: .4s ease-in-out;
  border-radius: 7px;
  cursor: pointer;
}

.fl {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: .2s ease-in-out;
}

.fl:hover .fullscreen {
  scale: 1.2;
}

.fl:hover .fullscreen_svg {
  fill: white;
}

.fullscreen {
  width: 1.5em;
  height: 1.5em;
  border-radius: 5px;
  background-color: #727890;
  margin: 1em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in-out;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
}

.fullscreen_svg {
  width: 15px;
  height: 15px;
  fill: rgb(177, 176, 176);
  transition: .2s ease-in-out;
}

.card_back-video {
  position: absolute;
  width: 15em;
  height: 14em;
  background-color: rgba(30, 31, 38, 0.575);
  border-radius: 7px;
  margin-top: -8.3em;
  margin-left: 0em;
  transition: .2s ease-in-out;
  z-index: -1;
}

.main-video:hover .card_back-video {
  margin-top: -8.9em;
  margin-left: 0em;
  scale: 1.1;
  height: 15.5em;
  cursor: pointer;
}

.main-video:hover .fl {
  opacity: 1;
  cursor: pointer;
  margin-right: 0.5em;
}

.data-video {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.video-header {
  display: flex;
  align-items: center; /* Aligne les éléments au centre verticalement */
  gap: 0.5em; /* Espacement entre le logo et les textes */
}

.channel-logo {
  width: 2.5em; /* Taille fixe du logo */
  height: 2.5em; /* Taille fixe du logo */
  border-radius: 5px;
  object-fit: cover; /* Assure que le logo conserve ses proportions */
}

.video-info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre verticalement le texte */
  flex: 1; /* Permet au conteneur de texte d'occuper l'espace restant */
}

.text_m {
  font-weight: bold;
  font-size: 0.9em;
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin: 0; /* Supprime les marges pour éviter les espacements indésirables */
}

.text_s {
  font-size: 0.7em;
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin: 0; /* Supprime les marges pour éviter les espacements indésirables */
}


.text-video {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 0.5em;
  font-family: Montserrat;
  color: white;
}


.btns {
  display: flex;
  gap: 0.5em;
  margin-top: 0.25em;
  transition: .2s ease-in-out;
}

.likes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 1.4em;
  border-radius: 4px;
  margin-top: -0.5em;
  opacity: 0;
  background-color: #444857;
  transition: .2s ease-in-out;
}

.likes_text {
  font-family: Montserrat;
  font-size: 0.8em;
  margin-left: 0.25em;
  color: white;
}

.likes_svg {
  width: 12px;
  height: 12px;
  fill: white;
}

.likes:hover {
  background-color: #5A5F73;
  cursor: pointer;
}

.comments {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 1.4em;
  border-radius: 4px;
  margin-top: -0.5em;
  opacity: 0;
  background-color: #444857;
  transition: .24s ease-in-out;
}

.comments_text {
  font-family: Montserrat;
  font-size: 0.8em;
  margin-left: 0.25em;
  color: white;
}

.comments_svg {
  width: 12px;
  height: 12px;
  fill: white;
}

.comments:hover {
  background-color: #5A5F73;
  cursor: pointer;
}

.views {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 1.4em;
  border-radius: 4px;
  margin-top: -0.5em;
  opacity: 0;
  background-color: #444857;
  transition: .28s ease-in-out;
}

.views_text {
  font-family: Montserrat;
  font-size: 0.8em;
  margin-left: 0.25em;
  color: white;
}

.views_svg {
  width: 12px;
  height: 12px;
  fill: white;
}

.views:hover {
  background-color: #5A5F73;
  cursor: pointer;
}

.main-video:hover .likes {
  margin-top: 0.5em;
  opacity: 1;
}

.main-video:hover .comments {
  margin-top: 0.5em;
  opacity: 1;
}

.main-video:hover .views {
  margin-top: 0.5em;
  opacity: 1;
}

.card_content {
  display: flex;
  align-items: center;
  justify-content: center;
}



@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

