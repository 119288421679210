/* Menu Container */
.menu-ishka {
    position: relative; /* Position relative pour le conteneur parent */
    width: 100%;
    height: 100vh; /* Hauteur de la vue pour centrer verticalement */
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de se placer en plusieurs lignes */
    justify-content: center;
    align-items: center;
}

/* Menu Items */
.menu-item-ishka {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5d3115;
    filter: drop-shadow(0px 0px 30px #DC9424);
    border-radius: 2px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease all;
}

.menu-item-ishka:hover {
  filter: drop-shadow(0px 0px 30px #5d3115);
}

.menu-item-ishka:nth-child(1) { margin-top: 93px; margin-left: -334px; width: 132px; height: 132px; background-color: #5d3115;}
.menu-item-ishka:nth-child(2) { margin-top: -302px; margin-left: 395px; width: 132px; height: 132px;background-color: #5d3115;}
.menu-item-ishka:nth-child(3) { margin-top: -301px; margin-left: 782px; width: 132px; height: 132px;background-color: #5d3115;}
.menu-item-ishka:nth-child(4) { margin-top: 92px; margin-left: -721px; width: 132px; height: 132px;background-color: #5d3115;}
.menu-item-ishka:nth-child(6) { margin-top: 90px; margin-left: 782px; width: 132px; height: 132px; background-color: #5d3115;}
.menu-item-ishka:nth-child(5) { margin-top: -298px; margin-left: -719px; width: 132px; height: 132px; background-color: #5d3115;}
.menu-item-ishka:nth-child(7) { margin-top: -103px; margin-left: 30px; width: 250px; height: 250px;background-color: #5d3115;}

.menu-item-inner-ishka {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff; /* Couleur du texte */
    transform: rotate(45deg); /* Applique une rotation de 45 degrés */
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Animation fluide pour le texte */
}

.with-text {
    color: #ffffff;
    background-color: #5d3115;
  }
  
  /* Styles spécifiques aux éléments avec effet */
  .special-effect {
    transition: 0.3s ease all;
  }
  
  .special-effect:before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: '';
    background-color: #ffffff;
    z-index: -1;
    border-radius: 2px;
  }
  
  .special-effect:hover .menu-item-inner-ishka {
    color: #5d3115;
  }
  
  .special-effect:hover:before {
    left: 0;
    right: 0;
    opacity: 1;
    background-color: #ffffff;
  }
  
  /* Effet de clic */
  .with-text:active {
    transform: scale(0.9);
  }

  .menu-item-ishka:nth-child(6) {
    background-color: #5d3115;
    box-shadow: none; /* Enlever les ombres */
    cursor: default; 
  }

  @media (max-width: 1000px) {
    .menu-ishka {
      width: 100%;
      height: 100%;
  }
}

  