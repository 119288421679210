.text-metodoFeldenkrais {
    text-align: justify;
    line-height: 1.6;
    color: white;
    padding: 50px;
    font-size: 18px;
  }

  
  .img-feldenkrais {
    float: left; /* Positionne l'image à droite */
    margin-left: 20px; /* Espace entre l'image et le texte */
    width: 350px;
    margin-right: 40px;
    margin-bottom: 40px;
    padding: 7px; /* Ajoute de l'espace entre la photo et le cadre */
    border: 1px solid rgb(133, 129, 129); /* Ajoute un cadre noir autour de la photo */
    background-color: rgb(26 41 6); 
  }

  @media (max-width: 1000px) {
    .text-metodoFeldenkrais {
      padding: 5px; /* Réduit le padding pour les petits écrans */
      font-size: 16px; /* Réduit la taille de la police */
    }
  
    .img-feldenkrais {
      float: none; /* Empêche l'image de flotter et la place au-dessus ou en dessous du texte */
      margin: 0 auto 20px; /* Centrer l'image et donner un espacement en bas */
      width: 100%; /* L'image prend toute la largeur disponible */
      max-width: 350px; /* Garde une taille d'image maximale */
    }
  }